<template>
  <div id="DPrescription" class="w-full">
    <div class="w-full">
      <div class="text-center mt-8">
       
        
              <imageLazy
                      :imageStyle="{ }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + Patient.ProfileImagePath"
                      placeHolderType="patient"
                    />
        <div class="">
          <p>{{ Patient.Name }}</p>
        </div>
      </div>
    </div>
    <b-row >
      <b-col cols="12" md="6">
        <span class="text-2xl mb-2">{{ $t("PatientDiagnosis") }} </span>
        <b-card class="w-full mb-5">
          <article class="text-xl w-full" v-if="Model.Diagnosis&&Model.Diagnosis!=''">
            {{ Model.Diagnosis }}
          </article>
          <p v-else class="warning">{{$t('NoDiagnosisHasbeenadded')}}</p>
        </b-card> 
        <span class="text-2xl mb-2 ">{{ $t("DoctorsNotes") }} </span>
        <b-card class="w-full">
          <article class="text-xl w-full" v-if="Model.DoctorNote&&Model.DoctorNote!=''">
            {{ Model.DoctorNote }}
          </article>
          <p v-else class="warning">{{$t('NoNoteHasbeenadded')}}</p>
        </b-card>
      </b-col>
      
      <b-col cols="12" md="6">
        <div>
          <div class="flex justify-between">
            <div>
              <span class="text-2xl mb-2"> {{ $t("Prescription") }} </span>
            </div>
          </div>
          <b-card v-if="Model.DoctorName&&Model.DoctorName!=''">
            <div class="mb-4">
              <h2 class="font-bold">{{ $t("Dr") }}.{{ Model.DoctorName }}</h2>
            </div>

            <div class="flex justify-between mx-4 mb-2">
              <div>
                <span> {{ Model.Address }} </span>
              </div>
              <!-- <div>
                <span> {{ $t("Speciality") }} : {{ Model.Name }} </span>
              </div> -->
            </div>
            <div class="flex justify-between mx-4 mb-6">
              <div>
                <span> {{ Model.Country }} </span>
              </div>
              <div>
                <span>
                  {{ $t("LicenseNumber") }} :
                  {{ Model.DoctorLocalLicenseNumber }}
                </span>
              </div>
            </div>
            <fieldset>
              <legend class="">{{ $t("Prescription") }}</legend>
              <table style="width: 100%" class="mt-2">
                <tr>
                  <th class="text-lg">{{ $t("TradeName") }}</th>
                   <th class="text-lg">{{ $t("ActiveIngredients") }}</th>
                  <th class="text-lg">{{ $t("Dossage") }}</th>
                  <th class="text-lg">{{ $t("Duration") }}</th>
                </tr>
                <tr
                  class="mx-auto"
                  v-for="item in Model.PatientReservatientSessionMedicals"
                  :key="item.ID"
                >
                  <td>
                    <vs-input
                      class="medcine"
                      disabled
                      v-model="item.MedicineName"
                    >
                    </vs-input>
                  </td>
                   <td>
                    <vs-input
                      class="medcine"
                      disabled
                      v-model="item.ActiveIngredients"
                    >
                    </vs-input>
                  </td>
                  <td>
                    <vs-input class="" disabled v-model="item.Dose"> </vs-input>
                  </td>
                  <td>
                    <vs-input class="" disabled v-model="item.DurationInDay">
                    </vs-input>
                  </td>
                </tr>
              </table>
            </fieldset>
          </b-card>
            <b-card class="w-full" v-else>
               <p  class="warning">{{$t('NoPrescriptionHasbeenadded')}}</p>
            </b-card>
        </div>
      </b-col>
    </b-row>
    
    <b-row >
        <b-col >
          <div class="mt-8 ">
            <b-button   @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import {
BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow
} from 'bootstrap-vue';
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
BButton, BCard, BCol, BFormInput, BInputGroup, BInputGroupAppend, BLink, BRow,
    imageLazy
  },
  data() {
    return {
      EnglishLan:false,
      baseURL: domain,
      Model: {},
      Patient :{}
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  created() {
 this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    debugger;
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    var Obj = {
      patientReservationSessionID:
        this.$route.params.patientReservationSessionID,
      patientID: this.$route.params.patientID,
    };
    
    this.$store
      .dispatch("patientList/SearchReservationSessionPrescriptions", Obj)
      .then((res) => {
        if (res.status == 200) {
          if(res.data.Data!=null&&res.data.Data.length>0)
                this.Model = res.data.Data[0];
        }
      })
      .catch(() => {
        window.showError();
      });

       this.$store
      .dispatch("patientList/GetPatient", this.$route.params.patientID)
      .then((res) => {
        this.Patient = res.data.Data;
      });

  },

  computed: {},
};
</script>
<style >
#DPrescription .vs-button {
  display: inline;
  background-color: transparent !important;
  color: gray;
  width: 5rem !important;
}
#DPrescription fieldset {
  border-collapse: separate;
  border: solid #00000029 1px;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
  padding: 15px;
}

#DPrescription .medcine {
  background: #e8e8e8;
}
#DPrescription table td {
  padding: 4px;
}
.doctorImage {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
#DPrescription .vs-input {
  width: 90%;
  color: black !important;
}
#DPrescription legend {
  margin-left: 2rem;
}
</style>